<template>
  <div class="tab">
    <div class="tab-box">
      <div v-for="(item, index) in list" :key="index" :class="[tabIndex == index ? 'active' : '']" @click="selectTab(index)">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.tabIndex = index;
      this.$emit('selectTab', index);
    },
  },
};
</script>
<style lang="less" scoped>
.tab {
  display: flex;
  color: #666;
  .tab-box {
    display: flex;
    border: 2px solid #ebedf0;
    div {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 157px;
      border-right: 2px solid #ebedf0;
      &:last-child {
        border: 0;
      }
    }
    .active {
      background: #edf0ff;
      color: #409EFF;
    }
  }
}
</style>
