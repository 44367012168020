<template>
  <div class="overall_kanban">
    <div class="box">
      <el-image v-show="is_move" class="data-left" :src="require('@/assets/image/data-left.png')" @click="scrollLeft"></el-image>
      <div ref="pay_num" class="pay_num">
        <div class="pay_num_box" :style="move">
          <div
            :class="['item', item.checked ? 'item-active' : '']"
            :style="{ width: item.width ? item.width : '' }"
            @click.prevent="selectPayNum(item, index)"
            v-for="(item, index) in content.num_list"
            :key="index"
          >
            <p>
              {{ item.name }}
              <el-checkbox v-model="item.checked" fill="#5654F3"></el-checkbox>
            </p>
            <p>{{ item.num_today }}</p>
            <p>
              <span>
                较前一日
                <el-image v-show="item.num_yesterday > 0" :src="require('@/assets/image/data-shang.png')"></el-image>
                <el-image v-show="item.num_yesterday < 0" :src="require('@/assets/image/data-xia.png')"></el-image>
              </span>
              <span :style="{ color: item.num_yesterday > 0 ? '#F10011' : item.num_yesterday < 0 ? '#2DA641' : '' }">{{ Math.floor(item.num_yesterday * 100) / 100 }}%</span>
            </p>
            <p>
              <span>
                较上周周期
                <el-image v-show="item.num_last_week > 0" :src="require('@/assets/image/data-shang.png')"></el-image>
                <el-image v-show="item.num_last_week < 0" :src="require('@/assets/image/data-xia.png')"></el-image>
              </span>
              <span :style="{ color: item.num_last_week > 0 ? '#F10011' : item.num_last_week < 0 ? '#2DA641' : '' }">{{ Math.floor(item.num_last_week * 100) / 100 }}%</span>
            </p>
          </div>
        </div>
      </div>
      <el-image v-show="is_move" class="data-right" :src="require('@/assets/image/data-right.png')" @click="scrollRight"></el-image>
    </div>
    <div class="pay_num_checked">
      <div class="reset">
        <span>已选{{ num_checked.length }}/{{ content.num_list.length }}</span>
        <el-button type="text" @click="resetPayNum">重置</el-button>
      </div>
    </div>
    <div :id="domId" style="height: 360px; width: 100%"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    domId: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
    },

    type: {
      type: Number, // 为1 异步加载数据
      default: 0,
    },
  },
  data() {
    return {
      index: 0,
      is_move: !1,
      num_checked: [],
      move: {},
      scrollWidth: '',
    };
  },
  mounted() {
    if (this.$refs.pay_num.scrollWidth > this.$refs.pay_num.clientWidth) this.is_move = !0;
  },
  methods: {
    scrollLeft() {
      this.move = {
        transform: `translateX(0px)`,
        transition: 'all 0.3s linear',
      };
    },
    scrollRight() {
      if (!this.scrollWidth) this.scrollWidth = this.$refs.pay_num.scrollWidth - this.$refs.pay_num.clientWidth;   
      if (this.scrollWidth > 0) {
        this.move = {
          transform: `translateX(-${this.scrollWidth}px)`,
          transition: 'all 0.3s linear',
        };
      }
    },
    selectPayNum(row, index) {
      if (this.type) {
        if (!row.num_arr) {
          if (this.num_checked.length < 5) {
            this.$emit('getThirtyDataKanban', index);
          } else {
            this.$message.warning('最多可选择5个指标');
          }
        } else {
          let len = this.num_checked.length;
          if (len == 5 && !row.checked) return this.$message.warning('最多可选择5个指标');
          if (len <= 5) {
            this.content.num_list[index].checked = !row.checked;
            this.initData();
          }
        }
      } else {
        this.content.num_list[index].checked = !row.checked;
        this.initData();
      }
    },
    resetPayNum() {
      this.num_checked = [];
      this.content.num_list.map(item => (item.checked = !1));
      this.initData();
    },
    initData() {
      let myChart = echarts.init(document.getElementById(this.domId));
      let content = this.content;
      let data = content.data;
      let series = [];
      let legend = [];
      data.xAxis.data = content.date;
      content.num_list.map(item => {
        let obj = {
          name: item.name,
          type: 'line',
          data: [],
        };
        if (item.checked) {
          obj.data = item.num_arr;
          legend.push(item.name);
        }
        series.push(obj);
      });
      data.series = series;
      this.num_checked = legend;
      data.legend.data = legend;
      myChart.clear(data);
      myChart.setOption(data);
    },
  },
};
</script>

<style lang="less" scoped>
.overall_kanban {
  margin-bottom: 30px;
  .box {
    position: relative;
    display: flex;
    align-items: center;
    .data-left {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .data-right {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
  .pay_num {
    flex: 1;
    margin-top: 26px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .pay_num_box {
      display: flex;

      // transition:all 1s linear;
      .item {
        flex: none;
        width: 186px;
        height: 161px;
        border: 1px solid #ebedf0;
        border-radius: 11px;
        padding: 0 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;
        p {
          display: flex;
          align-items: center;
          font-weight: 500;
          justify-content: space-between;
          .el-image {
            width: 8px;
            height: 13px;
          }
        }
        p:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        p:nth-child(2) {
          font-size: 24px;
          margin-bottom: 14px;
        }
        p:nth-child(3) {
          margin-bottom: 8px;
        }
        p:nth-child(3),
        p:nth-child(4) {
          color: #999;
        }
      }
      .item-active {
        background: #e5eeff;
        border: 1px solid #409eff;
      }
    }
  }
  .pay_num_checked {
    height: 55px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    .reset {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      span {
        color: #999;
        margin-right: 9px;
      }
    }
  }
}
</style>
